@mobile-cutoff: 800px;
@mobile-font-size: 12px;

.body{
    font-size: (calc(1rem + 0.1vw));
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 40px;
}

.link{
    color: #2162a2;
    font-family: 'Open Sans', 'regular';
    font-size: 0.75rem;
    font-weight: 400;
}

.greyText{
    color: #6c6c6c;
    font-weight: 400;
    font-family: 'Open Sans', 'regular';
    font-size: 1.125rem;
}


.ActionButton {
    @media only screen and (max-width: @mobile-cutoff) {
      width: fit-content;
      height: fit-content;
      font-size: large;
      padding-left: 25px;
      padding-right: 25px;
    }
    min-width: fit-content;
    width: calc(6.5rem + 3vw);
    height: calc((6.5rem + 3vw)/3);
    font-weight: 500;
    font-size: calc(1rem + 0.1vw);
    color: #292929;
    background-color: #ffca0f;
  }


.PageWrapper {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
}