@import './themes.less';

.pubcardcontainer {
  display: flex;
  flex-direction: row;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    > div {
      text-align: start;
      font-size: @font-normal-size;
    }

    img {
      border-radius: 12px;
      box-shadow: 3px 6px 20px 5px #c9c9c9;
      height: 320px;
      width: 350px;
      object-fit: cover;
    }
  }

  h4 {
    text-align: start;
    color: @primary-color;
    margin-bottom: @extra-small-size;
  }

  a {
    display: flex;
    align-items: center;
    font-size: @font-normal-size;
    justify-content: space-between;
    span {
      margin-left: 0.5rem;
    }
  }
}

.pubcardtext {
  flex: 2;
  justify-content: space-between;
  padding-left: 2rem;
}

.pubcarddate {
  color: @warm-grey;
  font-size: @font-small-size;
}

.pubstarttext {
  text-align: start;
  font-size: @font-normal-size;
  margin-bottom: 12%;
}

.pubgridlayout {
  display: grid;
  grid-row-gap: 5rem;
  grid-auto-rows: max-content;
}

@media screen and (max-width: 1000px) {
  .pubcardcontainer {
    flex-direction: column;
    > div {
      margin: 1rem 0;
      align-items: center;
      flex: 1.25;
      img {
        height: 180px;
        width: 250px;
      }
      >div {
        line-height: 3rem;
      }
    }
  }

  .pubcardtext {
    padding-left: 0;
    padding: 0 2rem;
  }

  .pubgridlayout {
    grid-row-gap: 1rem;
  }
}

@primary-color: #0b688a;@font-size-base: 1rem;@text-color: #292929;@layout-body-background: #fffff;@btn-font-weight: 600;