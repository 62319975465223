.titledivider {
  border-top: 9px solid @primary-color;
  width: 8rem;
  min-width: 0;
}

.titlecontainer {
  > h3 {
    color: @primary-color;
    margin-bottom: 3rem;
  }
}
@primary-color: #0b688a;@font-size-base: 1rem;@text-color: #292929;@layout-body-background: #fffff;@btn-font-weight: 600;