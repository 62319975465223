@primary-color: #0b688a;
@mobile-cutoff: 800px;
//issue found:
/*when code is compiled, less => css; therefore, variable can't be changed during runtime,
so instead, @font-base-size is changed to 1rem and is changed according to font-size in html element
*/
html {
  font-size: 16px;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: @mobile-cutoff) {
  html {
    font-size: 12px
  }
}

// font size
@font-large-size: 1.4rem;
@font-medium-size: 1.2rem;
@font-normal-size: 1rem;
@font-small-size: 0.8rem;
@font-extra-small-size: 0.6rem;

// color
@warm-grey: #979797;

// size
@extra-large-size: 2rem;
@large-size: 1.8rem;
@big-size: 1.4rem;
@medium-size: 1.2rem;
@normal-size: 1rem;
@small-size: 0.8rem;
@extra-small-size: 0.6rem;

@navbar-height: 5rem;
@screen-padding: 8rem;