//@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
@import 'globalSettings.module.less';
@import 'themes.less';

.MasterLayout {
  padding: 0;
}

.FooterLogo {
  max-width: 50%;
  width: 100%;
}

.FooterLinksContainer {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
}

.FooterLink {
  line-height: 2em;
  margin-left: 1rem;
  margin-right: 1rem;
}

.FooterContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.MasterContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 3rem;
  background-color: transparent;
}

.MasterFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  background-color: #dbdbdb;
  min-height: 10rem;

  // :hover {
  //   transform: scale(1.01);
  // }
}

.Icons {
  height: 1.5rem;
  width: auto;
  margin: auto;
}

.MenuHeader {
  padding: 0;
  font-weight: normal;
  background-color: transparent;
  position: absolute;
  z-index: 100;
  height: @navbar-height;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.MenuCol {
  position: absolute;
  padding-right: 3rem;
  right: 0;
  align-items: right;
}


@media screen and (max-width: 1000px) {
  .MasterFooter {
    flex-direction: column;
  }
  .FooterLogo {
    max-width: 100%;
    width: 100%;
    margin: auto;
  }
  .FooterContainer {
    align-items: center;
  }
  .FooterLinksContainer {
    justify-content: center;
    align-items: center;
    margin-top: 2rem;    
  }
}
@primary-color: #0b688a;@font-size-base: 1rem;@text-color: #292929;@layout-body-background: #fffff;@btn-font-weight: 600;