@import 'globalSettings.module.less';
@import 'themes.less';

.appIFrame {
  flex: 1;
  width: 100%;
  border: none;
  padding: 1rem;
  &:first-child {
    margin-right: 2rem;
    margin-bottom: 0;
  }
}

@media screen and (max-width: @mobile-cutoff) {
  .appIFrame {
    width: 100vw;
  }
}

.dashboardTabs {
  > div {
    > div {
      display: flex;
      justify-content: center;
    }
  }
}

.dashboardTab {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.nationalDashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  width: inherit;
}

.nationalDashboardControl {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.nationalDashboardControlLabel {
  font-size: @font-medium-size;
  font-weight: 700;
  padding-right: 2rem;
}

.stateDashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.stateDashboardControl {
  width: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.stateDashboardControlLabel {
  font-size: @font-large-size;
  font-weight: 700;
}

.titletabpane {
  font-size: @font-medium-size;
  margin: 0;
}

.dropDowns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@primary-color: #0b688a;@font-size-base: 1rem;@text-color: #292929;@layout-body-background: #fffff;@btn-font-weight: 600;