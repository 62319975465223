@import 'globalSettings.module.less';
@import 'themes.less';

@banner-height: calc(@navbar-height*2);

.trackpagetext {
  text-align: start;
}

.trackkeyterms {
  > p {
    font-weight: bold;
    margin: 0;
  }
  > div {
    font-size: @font-medium-size;
  }
}

.LandingPageFullWidthImagesContainer {
  min-height: 550px;
  height: @banner-height;
  width: 100vw;
  object-fit: cover;
  position: absolute;
  margin: 0 -@screen-padding;
}

.ImageStyle {
  filter: brightness(0.5);
  width: 100%;
}

.landingpagebanner {
  height: @banner-height;
  // min-height: 550px;
  // position: relative;
  > div {
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
  }
}

.landingpagecontainer {
  display: flex;
  flex-direction: column;
  padding: 0 @screen-padding;
  overflow-x: hidden;
  position: relative;
}

// Needs to be changed
.LandingPageOverlayText {
  margin: auto;
  color: #000000;
  font-size: 3.5rem;
  font-weight: 400;
  text-align: left;
  line-height: 7.157rem;
  letter-spacing: 0.1rem;

  button {
    background-color: #3b9ec2;
    line-height: 2.15;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 3rem;
    height: 4.79542334076rem;
    padding: 0 1rem;
    transition-duration: 0.4s;
  }

  button:hover {
    background-color: #3fcafc;
  }

  > div {
    display: inline-block;
  }
}

.underlineoverlay {
  display: inline-flex;
  position: relative;
  > strong {
    color: #000;
    font-size: 4rem;
    font-weight: bold;
    line-height: 1;
    z-index: 55;
  }
  > span {
    position: absolute;
    border-bottom: 0.8rem solid #3b9ec2;
    width: 98.5%;
    bottom: -0.1rem;
    right: 0;
    z-index: 50;
  }
}

.ContentText {
  line-height: 2rem;
  font-size: 1.1rem;
}

.TeamProfileRow {
  margin: 0 auto;
  // display: table;
}

.TeamProfileContainer {
  padding-left: 5vw;
  padding-right: 5vw;
}

.contentbody {
  margin-top: 5rem;
  > div {
    margin: 6rem 0;
  }
  > div:first-child {
    font-size: 1.2rem;
    margin-bottom: 4rem;
  }
}

.cardSection {
  display: flex;
  flex-direction: column;

  .navigationButton {
    font-size: @font-medium-size;
    font-weight: 600;
    align-self: center;
    margin-top: 2rem;
    background: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: 0;
    border-radius: 0.5rem;
    border-bottom: 4px solid #3b9ec2;

    &:hover {
      background-color: #eee;
    }
  }
}

@media screen and (max-width: @mobile-cutoff) {
  .LandingPageOverlayText {
    font-size: 2.5rem;
  }

  .UnderlineBlockWrapper strong {
    font-size: 2.5rem;
  }

  .UnderlineBlock {
    width: 8rem;
  }

  .LandingPageFullWidthImagesContainer {
    margin: 0 -1rem;
  }

  .landingpagecontainer {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
  }

  .appIFrame {
    height: 600px;
  }
}


@primary-color: #0b688a;@font-size-base: 1rem;@text-color: #292929;@layout-body-background: #fffff;@btn-font-weight: 600;