@import 'globalSettings.module.less';

.banner {
    position: relative;
    background: #0B688A;
    width: 100%;
    z-index: 101;
    text-align: center;
    font-size: 1rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    // The color of the link text 
    a {
        color: white;
    }

    strong {
        color: #0b688a;
    }
}

@media screen and (max-width: @mobile-cutoff){
    .banner {
        display: block;
        height: auto;
        line-height: 1.5rem;
        padding: 1rem;
    }
}
@primary-color: #0b688a;@font-size-base: 1rem;@text-color: #292929;@layout-body-background: #fffff;@btn-font-weight: 600;