@import './themes.less';

.Content { 
    font-size: @font-medium-size;
}

.twitterWidgetContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    padding: 1rem;
}
@primary-color: #0b688a;@font-size-base: 1rem;@text-color: #292929;@layout-body-background: #fffff;@btn-font-weight: 600;