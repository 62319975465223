@import './themes.less';

.ourteampage {
  text-align: center;
  padding: 10%;
}

.teampagedivider {
  border-top: 9px solid @primary-color;
}

.myteamtitle {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10%;
  > h1 {
    color: @primary-color;
  }
}

.ourteampage_member {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ourteampage_avatar {
  box-shadow: 4px 4px 28px 0 rgba(0, 0, 0, 0.12);
  margin-right: 8%;
  flex-shrink: 0;
}

.ourteampage_text {
  > h3 {
    color: @primary-color;
  }
  > span {
    color: #373737;
  }
}

@media screen and (max-width: @mobile-cutoff) {
  .ourteampage_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
  }
}

.teamcardsgrid {
  display: grid;
  gap: @extra-large-size @extra-large-size;
  justify-content: stretch;
  align-items: stretch;
  grid-auto-columns: 20%;
  grid-auto-flow: row;
  grid-template-columns: 50% 50%;
  padding-right: 3%;
  padding-top: @normal-size;
}

.ourteamtabs {
  > div {
    > div {
      display: flex;
      justify-content: center;
    }
  }
}

.titletabpane {
  font-size: @font-medium-size;
  margin: 0;
  padding: @extra-small-size @big-size;
}

@media screen and (max-width: 1024px) {
  .titletabpane {
    padding: @extra-small-size @small-size;
  }
}

@media screen and (max-width: @mobile-cutoff) {
  .teamcardsgrid {
    grid-template-columns: 100%;
    row-gap: 3.5rem;
    margin: 1.5rem 0;
  }

  .ourteampage_member {
    flex-direction: column;
    align-items: center;
  }

  .ourteampage_text {
    align-items: center;
    > h3 {
      margin: 0.5rem;
    }
  }

  .ourteamtabs {
    > div {
      > div {
        justify-content: start;
      }
    }
  }

  .titletabpane {
    padding: 0;
  }
}

@primary-color: #0b688a;@font-size-base: 1rem;@text-color: #292929;@layout-body-background: #fffff;@btn-font-weight: 600;