@import './themes.less';

.faq-container {
    .faq-collapse-content  > * {
        line-height: 1.6rem;
    }
    .faq-collapse-panel-0 {
        border-top: 1px solid #d9d9d9;
    }
    .ant-collapse-header {
        .ant-typography {
            margin: 0;
            font-size: @font-large-size;
        }
    } 
    .ant-collapse-content {
        border-top: none;
        .ant-collapse-content-box {
            padding: 1rem;
        }
    }
    .ant-collapse {
        border: none;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        background-color: white;
        padding: 1.5rem 1rem;
    }

    .faq-collapse-icon {
        font-size: 1.8rem;
        font-weight: bold;
        text-align: center;
        color: #373737;
    }
    
}

@primary-color: #0b688a;@font-size-base: 1rem;@text-color: #292929;@layout-body-background: #fffff;@btn-font-weight: 600;