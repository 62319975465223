@import 'globalSettings.module.less';
@import 'themes.less';

@color: '#99999';

.MobileMenuButton {
  // background-color: white;
  // border: none;
  // :hover {
  //   background-color: red;
  // }
  width: 3rem;
  height: 3rem;
  color: green;
}

.MenuCol {
  position: absolute;
  padding-right: 3rem;
  right: 0;
  align-items: right;
}

.MenuWrap {
  width: 100%;
  display: flex;
  padding: 1rem 3rem;
  align-items: center;
  @media screen and (max-width: @mobile-cutoff) {
    justify-content: flex-start;
  }
}

.MenuBar {
  width: 100%;
  font-size: 1.25rem;
  background-color: transparent;
  border: transparent;
  color: black;
  justify-content: right;
  @media screen and (max-width: 800px) {
    justify-content: left;
  }
  > li {
    margin: 0 0 0 2rem;
    padding: 0;
    @media only screen and (max-width: 1100px) {
      margin: 0 0 0 0.5rem;
    }
    @media only screen and (max-width: 1000px) {
      margin: 0 0 0 0;
    }
  }
}

@media screen and (max-width: @mobile-cutoff) {
  .MenuBar {
    background-color: white;
    border: 1px solid white;
    font-size: @mobile-font-size;
    padding: 1vw;
    display: block;
    height: auto;
    > li {
      margin: 0;
    }
  }

  .MenuWrap {
    button {
      padding: 0 1rem;
    }
  }
}

.MenuRow {
  align-items: center;
}

.Logo {
  @media only screen and (max-width: @mobile-cutoff) {
    align-items: center;
    margin: 0 auto;
    // width: 50rem;
  }
  width: 20rem;
  max-height: 6rem;
  min-width: 8rem;
  transition: all 0.2s ease-in-out;
}

.Logo:hover {
  transform: scale(1.1);
}

.Logo:active {
  position: relative;
  top: 0.2vh;
}

@primary-color: #0b688a;@font-size-base: 1rem;@text-color: #292929;@layout-body-background: #fffff;@btn-font-weight: 600;