@import './themes.less';

.contentlayoutcontainer {
  width: 100%;
  padding: calc(@navbar-height + 3rem) 9%;
}

.contentlayoutdivider {
  border-top: 9px solid @primary-color;
}

.contentlayouttitlecontainer {
  display: flex;
  justify-content: flex-start;
  > h1 {
    color: @primary-color;
  }
}

.contentlayouttitlecontainer_centered {
  &:extend(.contentlayouttitlecontainer all);
  justify-content: center;
}

.contentstarttext {
  text-align: start;
  font-size: @font-medium-size;
  margin-bottom: 4rem;
}

@primary-color: #0b688a;@font-size-base: 1rem;@text-color: #292929;@layout-body-background: #fffff;@btn-font-weight: 600;